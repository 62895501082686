<template>
  <div class="download">
    <Banner></Banner>
    <h2 class="h2">最新版本</h2>
    <div class="news">
      <div class="edition">
        <div class="img" @click="windows(version)">
          <img src="@/assets/img/pc/product/news_win.png" class="img1" />
          <img src="@/assets/img/pc/product/download.png" class="img2" />
        </div>
        <h3>Windows</h3>
        <p>版本：{{ version.releaseCode }}</p>
        <p>{{ version.releaseDate }}</p>
        <p class="explain">
          系统要求
          <img v-popover:popover1 src="@/assets/img/pc/product/explain.png" />
        </p>
        <el-popover ref="popover1" placement="bottom" width="400" trigger="hover" popper-class="popper">
          <div>系统：Windows2016 / Windows2019</div>
          <div>浏览器：IE10+，Chrome，360，QQ</div>
          <div>硬件：CPU(8核+)/内存(16G)/硬盘(1T+)</div>
        </el-popover>
      </div>
      <div class="edition" style="margin-left: 1.052631rem">
        <div class="img" @click="linux(version)">
          <img src="@/assets/img/pc/product/news_linux.png" class="img1" />
          <img src="@/assets/img/pc/product/download.png" class="img2" />
        </div>
        <h3>Linux</h3>
        <p>版本：{{ version.releaseCode }}</p>
        <p>{{ version.releaseDate }}</p>
        <p class="explain">系统要求<img v-popover:popover2 src="@/assets/img/pc/product/explain.png" /></p>
        <el-popover ref="popover2" placement="bottom" width="400" trigger="hover" popper-class="popper">
          <div>系统：CENTOS 7.X</div>
          <div>浏览器：IE10+，Chrome，360，QQ</div>
          <div>硬件：CPU(8核+)/内存(16G)/硬盘(1T+)</div>
        </el-popover>
      </div>
      <div class="text">
        <h3>优化：</h3>
        <!-- <div>1、 新增答题认证方式，题库自动导入，答题 &emsp;&emsp;页面自定义。动态设置通过阈值。考题支 &emsp;&emsp;持随机或固定。</div>
				<div>2、 增加系统默认模板。</div>
				<div>3、 程序员哥哥偷偷修复了一些未知的问题。</div> -->
        <div v-html="html(version.releaseLog)"></div>
      </div>
    </div>
    <h2 class="h2">历史版本</h2>
    <div class="version">
      <div class="each" v-for="(item, index) in versionList" :key="index">
        <div class="htmls">
          <div>
            <span>版本：{{ item.releaseCode }}</span
            ><span>{{ item.releaseDate }}</span>
          </div>
          <p>优化:</p>
          <div v-html="html(item.releaseLog)"></div>
        </div>
        <div class="right">
          <div class="down">
            <span @click="windows(item)">Windows下载</span>
            <span @click="linux(item)">Linux下载</span>
          </div>
        </div>
        <div class="circular"><div></div></div>
      </div>
      <div class="more" v-if="isFoot"><span @click="more">查看更多</span><span class="icon-chakangengduo1 iconfont"></span></div>
      <div v-else class="mores">
        <span>我也是有底线的</span>
      </div>
    </div>
    <FootMain></FootMain>
  </div>
</template>

<script>
import Banner from '@/components/pc/product/product.vue'
import FootMain from '@/components/pc/commom/footmain/index.vue'
export default {
  computed: {
    version() {
      return this.$store.state.version
    },
    versionList() {
      return this.$store.state.versionList
    },
    isFoot() {
      return this.$store.state.isfoot
    }
  },
  data() {
    return {
      data: {
        page: {
          cur: 1,
          size: 5
        }
      }
    }
  },
  components: {
    Banner,
    FootMain
  },
  methods: {
    html(string) {
      return string
    },
    more() {
      this.data.page.cur += 1
      this.$store.dispatch('getVersionList', this.data)
    },
    windows(item) {
      let loginuser = localStorage.getItem('loginUser')
      if (loginuser == null || loginuser == 'undefined' || loginuser == '') {
        this.$router.push('/trial')
      } else {
        window.open(item.winDownloadUrl, '_blank')
        this.$store.dispatch('statistics', { id: item.id, type: 'win' })
      }
    },
    linux(item) {
      let loginuser = localStorage.getItem('loginUser')
      if (loginuser == null || loginuser == 'undefined' || loginuser == '') {
        this.$router.push('/trial')
      } else {
        this.$store.dispatch('statistics', { id: item.id, type: 'linux' })
        window.open(item.linuxDownloadUrl, '_blank')
      }
    }
  },
  created() {
    this.$store.dispatch('getVersionList')
  }
}
</script>
<style lang="scss" scoped>
.down {
  span {
    &:hover {
      color: #0ba95f !important;
    }
  }
}
.popper {
  div {
    color: #aaaaaa !important;
    font-size: 0.526315rem !important;
  }
}
.download {
  h2 {
    &:first-child {
      margin-top: 1.578947rem;
    }
    &:last-of-type {
      margin-top: 2.368421rem;
    }
  }
  .news {
    width: 47.631578rem;
    height: 16.578947rem;
    margin: 0 auto;
    margin-top: 1.842105rem;
    display: flex;
    .edition {
      text-align: center;
      width: 11.18421rem;
      height: 16.105263rem;
      background-image: url('~@/assets/img/pc/product/edition.png');
      background-size: 100%;
      .img {
        width: 5.789473rem;
        height: 5.789473rem;
        display: block;
        margin: 1.842105rem auto 0;
        position: relative;
        &:hover .img1 {
          transform: rotateY(180deg);
          opacity: 1;
          z-index: 1;
        }
        &:hover .img2 {
          transform: rotateY(0deg);
          opacity: 1;
          z-index: 2;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.5s ease-in-out;
          background-color: white;
          padding: 0 1px;
          cursor: pointer;
        }
        .img1 {
          z-index: 2;
        }
        .img2 {
          z-index: 1;
          opacity: 1;
          transform: rotateY(180deg);
        }
      }
      h3 {
        font-size: 1.578947rem;
        color: #333;
        margin: 0.789473rem 0;
      }
      p {
        font-size: 0.789473rem;
        color: #666;
        margin: 0;
      }
      .explain {
        color: #999;
        font-size: 0.631578rem;
        margin-top: 0.789473rem;
        img {
          display: inline;
          margin: 0 0.394736rem 0;
          vertical-align: middle;
          height: 0.789473rem;
          width: 0.789473rem;
          cursor: pointer;
        }
      }
    }
    .text {
      margin-left: 2.368421rem;
      width: 18.947368rem;
      h3 {
        font-size: 1.315789rem;
        color: #333;
      }
      // div{
      // 	font-size: 0.947368rem;
      // 	color: #888;
      // 	margin-bottom: 0.789473rem;
      // }
    }
  }
  .version {
    width: 43.421052rem;
    margin: 1.842105rem auto 0;
    border-left: 1px solid #999;
    box-sizing: border-box;
    // height: 31.578947rem;
    padding-top: 0.394736rem;
    .each {
      display: flex;
      justify-content: space-between;
      padding: 1.052631rem 5.263157rem 0 3.289473rem;
      box-sizing: border-box;
      min-height: 9.605263rem;
      width: 100%;
      position: relative;
      &:first-of-type {
        .circular {
          top: 1.18421rem;
        }
      }
      &:hover {
        background-color: #f5fbf3;
        .circular {
          border-color: #22ac38;
          div {
            display: block;
          }
        }
      }
      // .htmls {
      // max-width: 500px;
      // }
      div {
        font-size: 1.052631rem;
        color: #333333;
        span {
          &:first-child {
            margin: 0 6.052631rem 0 0;
          }
        }
      }
      p {
        font-size: 0.894736rem;
        color: #666;
        margin: 0.236842rem 0;
      }
      .right {
        align-self: center;
        div {
          span {
            color: #000;
            font-size: 0.894736rem;
            display: block;
            cursor: pointer;
            text-decoration: underline;
            width: 6.382978rem;
            margin-right: 0;
            &:first-child {
              margin-bottom: 0.921052rem;
            }
          }
        }
      }
      .circular {
        width: 1.18421rem;
        height: 1.18421rem;
        border-radius: 50%;
        border: 0.131578rem solid #707070;
        box-sizing: border-box;
        position: absolute;
        top: 0.315789rem;
        left: 0;
        transform: translateX(-50%);
        background-color: #fff;
        div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 0.342105rem;
          height: 0.342105rem;
          border-radius: 50%;
          background-color: #22ac38;
          display: none;
        }
      }
    }
  }
  .more {
    text-align: center;
    color: #2568d9;
    font-size: 0.894736rem;
    margin-top: 1.315789rem;
    span {
      font-size: 0.894736rem;
      cursor: pointer;
    }
  }
  .mores {
    text-align: center;
    color: #666;
    font-size: 0.894736rem;
    margin-top: 1.315789rem;
    span {
      font-size: 0.894736rem;
      cursor: pointer;
      position: relative;
      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 100px;
        height: 1px;
        border-bottom: 1px solid #666;
        left: -105px;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        display: block;
        content: '';
        position: absolute;
        width: 100px;
        height: 1px;
        border-bottom: 1px solid #666;
        right: -105px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.h2 {
  text-align: center;
  font-size: 1.578947rem;
  color: #333;
  margin: 0;
  &:before {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }
  &:after {
    display: inline-block;
    content: '';
    height: 0.263157rem;
    width: 5.526315rem;
    background-color: #11e323;
    vertical-align: middle;
    transform: translateY(-50%);
    margin: 0 0.394736rem;
  }
}
</style>
<style type="text/css">
.htmls p {
  font-size: 0.894736rem;
  color: #666;
  margin: 0.236842rem 0;
}
.text p {
  font-size: 0.947368rem;
  color: #888;
  margin: 0;
  margin-bottom: 0.789473rem;
}
</style>
